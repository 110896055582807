import { Injectable } from '@angular/core';
import { ActivationStart } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumb } from './interfaces';
import { BREADCRUMBS_ITEMS } from './const';
import { BreadcrumbItemsType } from './types';
import { ICategory } from '@services/categories';
import { ROUTES } from '@const';
import { IContent } from '@services/learn';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  existingHierarchy: BreadcrumbItemsType = BREADCRUMBS_ITEMS;

  breadcrumbsBeh$: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>([]);

  public breadcrumbs$: Observable<IBreadcrumb[]> = this.breadcrumbsBeh$.asObservable();

  public build(event: ActivationStart): void {
    this.reset();

    if (event.snapshot?.routeConfig) {
      const { data } = event.snapshot;

      const breadCrumb: IBreadcrumb = this.existingHierarchy[data.parent];

      if (breadCrumb) {
        let currentBreadcrumbs: IBreadcrumb[] = this.breadcrumbsBeh$.getValue();

        let futureValue: IBreadcrumb[];
        if (breadCrumb.children) {
          if (breadCrumb.children.children) {
            currentBreadcrumbs = [breadCrumb.children.children, ...currentBreadcrumbs];
          }
          futureValue = [...currentBreadcrumbs, breadCrumb.children, breadCrumb];
        } else {
          futureValue = [...currentBreadcrumbs, breadCrumb];
        }
        this.breadcrumbsBeh$.next(futureValue);
      }
    }
  }

  public addDynamicCategory(item: ICategory): void {
    const currentBreadcrumbs: IBreadcrumb[] = this.breadcrumbsBeh$.getValue();

    const breadcrumb: IBreadcrumb = {
      label: item.name,
      route: `${ROUTES.learn}/${ROUTES.categories}/${item.slug}`,
    };

    this.breadcrumbsBeh$.next([...currentBreadcrumbs, breadcrumb]);
  }

  public addDynamicContent({ id, type, title }: Pick<IContent, 'id' | 'type' | 'title'>): void {
    const currentBreadcrumbs: IBreadcrumb[] = this.breadcrumbsBeh$.getValue();

    const breadcrumb: IBreadcrumb = {
      label: title,
      route: `${ROUTES.learn}/${ROUTES.content}/${type}/${id}`,
    };

    this.breadcrumbsBeh$.next([...currentBreadcrumbs, breadcrumb]);
  }

  public addDynamicMarket(id: number, title: string, entity: 'wells' | 'tracts'): void {
    const currentBreadcrumbs: IBreadcrumb[] = this.breadcrumbsBeh$.getValue();

    const breadcrumb: IBreadcrumb = {
      label: title,
      route: `${ROUTES.market}/project/${id}/${entity}`,
    };

    this.breadcrumbsBeh$.next([...currentBreadcrumbs, breadcrumb]);
  }

  private reset(): void {
    this.breadcrumbsBeh$.next([]);
  }
}
