import { Injectable } from '@angular/core';
import {
  IContent,
  IContentExtended,
  IContentWithActualCategory,
  ContentVisibilityUtil,
  LearnLogicService,
  LearnService,
} from '../learn';
import { combineLatest, Observable, of } from 'rxjs';
import { BasePaginationDTO, IPaginatedResponse } from '@base/index';
import { GetContentDto } from './dtos';
import { Router } from '@angular/router';
import { BackendService, RequestFacadeModel, RequestModel, RequestType } from '@core/backend';
import { map, switchMap, tap } from 'rxjs/operators';
import { IBadgeCalculationResponse, IBadgeProps } from '@widgets/content-sections';
import { ROUTES } from '@const';
import { ID } from '@datorama/akita';
import { ICategory, ICategoryPreview } from '@services/categories';
import { AuthService } from '@services/auth';
import { ContentQuery, ContentStore, IContentLocal } from '@services/content/store';
import { limitItems } from '@utils/data-display';

@Injectable({
  providedIn: 'root',
})
export class ContentService extends LearnService {
  constructor(
    backendService: BackendService,
    private readonly router: Router,
    private readonly learnLogicService: LearnLogicService,
    private readonly authService: AuthService,
    private readonly contentStore: ContentStore,
    private readonly contentQuery: ContentQuery,
  ) {
    super(backendService, 'content');
  }

  public getAll(
    contentDto: GetContentDto | BasePaginationDTO,
  ): Observable<IPaginatedResponse<IContent> & IBadgeCalculationResponse> {
    let guestUserRulesApplied = contentDto instanceof GetContentDto && contentDto.most_recent;

    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(),
      skipNotify: true,
    }).withQuery<GetContentDto | BasePaginationDTO>(contentDto);

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });

    return combineLatest([
      this.authService.isAuthorized$,
      this.send<IPaginatedResponse<IContent> & IBadgeProps>(requestFacade),
    ]).pipe(
      tap(([isAuthorized, response]) => {
        guestUserRulesApplied = !isAuthorized && guestUserRulesApplied;
        if (guestUserRulesApplied) {
          const { results } = response;

          const viewedContent: IContentLocal[] = this.contentQuery
            .getAll()
            .filter((content: IContentLocal) => content.seen);

          this.contentStore.reset();

          this.contentStore.add(
            results.map((content: IContent) => ({
              ...content,
              seen: viewedContent.some((vC: IContentLocal) => vC.id === content.id),
            })),
          );
        }
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([isAuthorized, response]) => response),
      switchMap(response =>
        guestUserRulesApplied
          ? this.contentQuery.selectAll().pipe(
              map((items: IContentLocal[]) => items.filter((i: IContentLocal) => !i.seen)),
              switchMap((items: IContent[]) => of({ ...response, results: items })),
            )
          : of(response),
      ),
      map(response => {
        const badge: IBadgeProps = this.validateRecentBadge(contentDto)
          ? { label: response.results.length.toString(), styles: 'primary' }
          : {};
        return {
          ...response,
          results: response.results.map((content: IContent) =>
            ContentVisibilityUtil.mutateVisibility(content),
          ),
          badge,
        };
      }),
    );
  }

  public getById(id: ID): Observable<IContentExtended> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl(),
      requestBody: null,
    }).withID(id);
    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });

    const requestResult$: Observable<IContentExtended> = this.send<IContentExtended, null>(
      requestFacade,
    ).pipe(
      map(
        (content: IContentExtended) =>
          ContentVisibilityUtil.mutateVisibility(content) as IContentExtended,
      ),
    );

    return this.authService.isAuthorized$.pipe(
      switchMap(() => this.readContent(id)),
      switchMap(() => requestResult$),
    );
  }

  public getForCategory(
    categorySlug?: ID,
    page = 1,
    page_size = 10,
  ): Observable<IContentWithActualCategory> {
    const contentDto: GetContentDto = new GetContentDto({
      page,
      page_size,
      categories_slugs: categorySlug,
    });

    return this.getAll(contentDto).pipe(
      map(res => {
        const results = res.results;
        const hasNext = !!res.next ?? false;

        const firstContent: IContent = results.find(Boolean) as IContent;

        const actualCategory: ICategory = firstContent?.categories?.find(
          (category: ICategoryPreview) =>
            category.slug === categorySlug
        ) as ICategory;

        const contentWithoutFeatured: IContent[] = results.filter((content: IContent) => {
          if (page === 1) {
            return content.id !== firstContent.id;
          } else {
            return true;
          }
        });

        return {
          info: actualCategory,
          featuredContent: firstContent,
          content: contentWithoutFeatured,
          hasNext,
          count: res.count,
        };
      }),
    );
  }

  public readContent(id: ID): Observable<unknown> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl(null),
    })
      .withID(id)
      .withAdditionalUrl('read');

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.patch,
      request,
    });

    const requestResult$: Observable<unknown> = this.send<unknown, null>(requestFacade);

    return this.authService.isAuthorized$.pipe(
      switchMap((isAuthorized: boolean) => (isAuthorized ? requestResult$ : this.readAsGuest(id))),
    );
  }

  public viewAllRecentUpdates({ most_recent, featured }: Partial<GetContentDto>): void {
    this.router
      .navigate([`/${ROUTES.learn}/${this.getCorrectRoute({ featured })}`], {
        queryParams: { most_recent, featured },
      })
      .then();
  }

  public getRelatedContentSample(content: IContentExtended): Observable<IContent[]> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl(),
      requestBody: null,
    })
      .withID(content.id)
      .withAdditionalUrl('related/sample');
    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<IContent[], null>(requestFacade).pipe(
      map((items: IContent[]) => limitItems<IContent>({ results: items, showCount: 3 })),
    );
    // const firstCategory = content.categories.find(Boolean);
    // return this.getAll({
    //   page: 1,
    //   page_size,
    //   categories: firstCategory?.id,
    // }).pipe(map((response: IPaginatedResponse<IContent>) => response.results));
  }

  public getRelatedContent({
    id,
    page,
    page_size,
  }: Pick<IContent, 'id'> & BasePaginationDTO): Observable<IPaginatedResponse<IContent>> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl(),
      requestBody: null,
    })
      .withID(id)
      .withAdditionalUrl('related')
      .withQuery<BasePaginationDTO>({ page, page_size });
    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<IPaginatedResponse<IContent>, null>(requestFacade);
  }

  public validateRecentBadge(contentDto: GetContentDto): boolean {
    return !!(contentDto && contentDto.most_recent);
  }

  private getCorrectRoute({ featured }: Partial<GetContentDto>): string {
    return featured ? ROUTES.featuredStories : ROUTES.recentUpdates;
  }

  private readAsGuest(id: ID): Observable<unknown> {
    return of(null).pipe(tap(() => this.contentStore.readContent({ id })));
  }
}
