import { BasePaginationDTO } from '@base/classes';
import { FeaturedValue } from '../../learn';
import { ID } from '@datorama/akita';

class GetContentDto extends BasePaginationDTO {
  /**
   * Filter by featured type
   *
   * @type {FeaturedValue}
   */
  featured?: FeaturedValue;

  /**
   * Filter by categories ( IDS )
   *
   * @type {ID[]}
   */
  categories?: ID | ID[];

  /**
   * Filter by categories ( IDS )
   *
   * @type {ID[]}
   */
  categories_slugs?: ID | ID[];

  /**
   * Filter by most recent content.
   *
   * @type {boolean}
   */
  most_recent?: boolean;

  constructor({ featured, categories, categories_slugs, most_recent, page, page_size }: GetContentDto) {
    super({ page, page_size });
    this.featured = featured;
    this.categories = categories;
    this.categories_slugs = categories_slugs;
    this.most_recent = most_recent;
  }
}

export { GetContentDto };
