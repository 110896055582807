import { FeaturedValue } from '../enums';
import { WithContentType, WithID, WithImage, WithVisibility } from '@base/index';
import { ICategoryPreview } from '@services/categories';

interface IContent extends WithID, WithImage, WithVisibility, WithContentType {
  title: string;
  slug: string;
  description: string;
  url: string;
  source: string;
  featured: FeaturedValue;
  created_on: Date;
  date_modified: Date;
  date_read: Date;
  categories: ICategoryPreview[];

  author?: string;
}

export { IContent };
