import {
  WithID,
  WithImage,
  WithImageThumb,
  WithName,
  WithPosition,
  WithVisibility,
} from '@base/index';

interface ICategory
  extends WithID,
    WithPosition,
    WithName,
    WithImage,
    WithImageThumb,
    WithVisibility {
  content_count: number;
  slug: string;
}

export { ICategory };
