<div
  class="categories-content"
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="50"
  (scrolled)="handleScroll()"
>
  <div>
    <div
      class="mt-xl-7 mt-5"
      ec-unlock-content-overlay
      [state]="
        $any({
          visibility: section.actionLabel === 'Unlock' ? 'Accredited' : 'Public'
        })
      "
      position="bottom"
      *ngFor="let section of categoriesSections$ | async; trackBy: trackByCategorySection"
    >
      <ec-content-section [props]="section" (actionClick)="handleActionClick(section)">
        <ec-learn-category-items
          [isLocked]="section.actionLabel === 'Unlock'"
          [categorySlug]="section.content?.slug"
        ></ec-learn-category-items>
      </ec-content-section>
      <!--      <div ec-divider color="lgrey"></div>-->
    </div>
  </div>
  <ec-spinner-wrapper
    [loadingState]="(loadingService.loadingState$ | async) === true"
  ></ec-spinner-wrapper>
</div>
