import { ContentType, ContentVisibility, FeaturedValue, IContent } from '@services/learn';
import { ICategoryPreview } from '@services/categories';
import { ID } from '@datorama/akita';

class ContentModel implements IContent {
  categories!: ICategoryPreview[];

  created_on!: Date;

  date_modified!: Date;

  date_read!: Date;

  description!: string;

  featured!: FeaturedValue;

  id!: ID;

  image!: string;

  image_thumbnail!: string;

  title!: string;

  slug!: string;

  type!: ContentType;

  url!: string;

  source!: string;

  visibility!: ContentVisibility;
}

export { ContentModel };
