import { ICategory } from '../interfaces';
import { ContentVisibility } from '@services/learn';
import { ID } from '@datorama/akita';

class CategoryModel implements ICategory {
  id!: ID;

  content_count!: number;

  image!: string;

  name!: string;

  slug!: string;

  position!: number;

  visibility!: ContentVisibility;

  image_thumbnail!: string;
}

export { CategoryModel };
